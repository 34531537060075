<template>
  <div
    :id="`zotloFooter_${section?.id || ''}`"
    class="tw-zotlofooter"
    :class="{ dark: section.dark }"
  >
    <div
      class="tw-section-container mx-auto my-0 flex flex-col items-center"
      :style="(elStyle as any)"
    >
      <p
        class="tw-section-container-slogan mb-16 flex items-center text-10 font-semibold gap-8"
        v-html="purify(translate('generate.zotloFooter.supportedBy', locale, { img: logo }))"
      ></p>
      <nav class="tw-section-container-links flex flex-wrap items-center justify-center gap-12 text-10 md:gap-20">
        <a
          v-for="(agreement, key) in agreements"
          :key="key"
          :href="agreement"
          target="_blank"
          class="!text-[currentColor] !no-underline hover:!underline"
        >
          {{ translate(`generate.zotloFooter.${key}`, locale) }}
        </a>
      </nav>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType } from 'vue';
import type { Section, PageOptions } from '@shared/types/model';
import { getAssetFromCdn } from '@shared/utils/helpers';
import { useTranslate } from '@shared/composable/useTranslate';
import { purify } from '@shared/utils';
import { DOCS } from '../types';

const props = defineProps({
  section: { type: Object as PropType<Section>, default: () => ({}) },
  pageData: { type: Object, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  locale: { type: String, default: '' },
});

const { translate } = useTranslate();

const agreements = computed(() => {
  const locale = props.locale === 'tr' ? 'tr' : 'en';
  const links = props.pageData?.docLinks?.[locale];
  return {
    termsOfService: links?.[DOCS.TERMS_OF_SERVICE],
    privacyPolicy: links?.[DOCS.PRIVACY_POLICY]
  };
})

const logo = computed(() => {
  return `<img
    src="${getAssetFromCdn(`/img/zotlo-store-logo${props.section.dark ? '-white' : ''}.svg`)}"
    alt="Zotlo Store"
  />`;
})

const elStyle = computed(() => {
  return {
    ...props.section.style,
    fontFamily: props.section.style.fontFamily || props.pageOptions.fontFamily
  }
})
</script>

<style lang="postcss" scoped>
.tw-zotlofooter {
  @apply !bg-transparent;

  .tw-section-container {
    @apply w-full py-40 px-24 border border-transparent;

    &-slogan{ @apply text-[#000000]; }
    &-links{ @apply text-neutral-900; }
  }

  &.dark {
    .tw-section-container {
      @apply text-white;

      &-slogan, &-links{ @apply text-white; }
    }
  }
}
</style>
